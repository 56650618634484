import { Api } from "./api";

export default {
  getBalance: (payload) =>
    Api.get("/v2/balance", {
      headers: { token: payload.token },
    }),
  getBalanceTransaction: (payload) =>
    Api.get("/v2/balance/history", {
      headers: { token: payload.token },
    }),
  getBalanceHistoryDownload: (payload) =>
    Api.get("/v2/balance/history/download", {
      headers: { token: payload.token },
    }),
  getBalanceEst: (payload) =>
    Api.get("/v2/balance/estimated", {
      headers: { token: payload.token },
    }),
  requestWithdrawal: (payload) =>
    Api.post(`/v2/balance/withdraw/request-otp`, payload.data, {
      headers: { token: payload.token },
    }),
  confirmOtpRequestWithdrawal: (payload) =>
    Api.post(`/v2/balance/withdraw/confirm-otp`, payload.data, {
      headers: { token: payload.token },
    }),
};

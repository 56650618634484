import types from "./constants";

const initialState = {
  saldo: 0,
  isLoadingGetDeposit: false,
  history: {
    count: 0,
    data: [],
    loading: false,
  },
  est: {
    saldo: 0,
    loading: false,
  },
  withdraw_amount: 0,
  password: "",

  download: {
    data: [],
    loading: false,
    success: false,
  },

  destinationBankAccount_id: null,
  requestWithdrawalSuccess: false,
  isLoadingRequestWithdrawal: false,
  isLoadingConfirmOtpWithdrawal: false,
  withdrawSuccess: false,
  resendOtp: {},
  isLoadingResendOTP: false,
  success: false,
  message: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SELECTED_DESTINATION_BANK_ACCOUNT:
      return { ...state, destinationBankAccount_id: action.payload };
    case types.WITHDRAW_AMOUNT:
      return { ...state, withdraw_amount: action.payload };
    case types.PASSWORD_WITHDRAWAL:
      return { ...state, password: action.payload };
    case types.GET_BALANCE_SUCCESS:
      return { ...state, saldo: action.payload };
    case types.IS_LOADING_GET_BALANCE:
      return { ...state, isLoadingGetDeposit: action.payload };
    case types.GET_BALANCE_TRANSACTION_SUCCESS:
      return {
        ...state,
        history: {
          ...state.history,
          count: action.payload.count,
          data: action.payload.data,
        },
      };
    case types.IS_LOADING_GET_BALANCE_TRANSACTION:
      return {
        ...state,
        history: {
          ...state.history,
          loading: action.payload,
        },
      };
    case types.GET_BALANCE_ESTIMATED_SUCCESS:
      return {
        ...state,
        est: {
          ...state.est,
          saldo: action.payload,
        },
      };
    case types.IS_LOADING_GET_BALANCE_ESTIMATED:
      return { ...state, est: { ...state.est, loading: action.payload } };
    case types.REQUEST_WITHDRAWAL_SUCCESS:
      return { ...state, requestWithdrawalSuccess: action.payload };
    case types.IS_LOADING_REQUEST_WITHDRAWAL:
      return { ...state, isLoadingRequestWithdrawal: action.payload };
    case types.CONFIRM_OTP_WITHDRAWAL_SUCCESS:
      return { ...state, withdrawSuccess: action.payload };
    case types.IS_LOADING_CONFIRM_OTP_WITHDRAWAL:
      return { ...state, isLoadingConfirmOtpWithdrawal: action.payload };
    case types.IS_LOADING_RESEND_OTP:
      return { ...state, isLoadingResendOTP: action.payload };
    case types.SET_MESSAGE:
      return {
        ...state,
        success: action.payload.success,
        message: action.payload.message,
      };
    case types.GET_DOWNLOAD_BALANCE_HISTORY_SUCCESS:
      return {
        ...state,
        download: {
          ...state.download,
          data: action.payload,
        },
      };
    case types.IS_LOADING_GET_DOWNLOAD_BALANCE_HISTORY:
      return {
        ...state,
        download: {
          ...state.download,
          loading: action.payload,
        },
      };

    case types.RESET_BALANCE:
      return initialState;
    default:
      return state;
  }
};

export const getDownloadBalanceHistory = (payload) => ({
  type: types.GET_DOWNLOAD_BALANCE_HISTORY,
  payload,
});
export const getDownloadBalanceHistorySuccess = (payload) => ({
  type: types.GET_DOWNLOAD_BALANCE_HISTORY_SUCCESS,
  payload,
});
export const isLoadingGetDownloadBalanceHistory = (payload) => ({
  type: types.IS_LOADING_GET_DOWNLOAD_BALANCE_HISTORY,
  payload,
});

// SELECT BANK ACCOUNT
export const selectDestinationBankAccount = (payload) => ({
  type: types.SELECTED_DESTINATION_BANK_ACCOUNT,
  payload,
});
export const changeWithdrawAmount = (payload) => ({
  type: types.WITHDRAW_AMOUNT,
  payload,
});
export const changePasswordWithdrawal = (payload) => ({
  type: types.PASSWORD_WITHDRAWAL,
  payload,
});
//GET_BALANCE
export const getDeposit = (payload) => ({
  type: types.GET_BALANCE,
  payload,
});
export const getDepositSuccess = (payload) => ({
  type: types.GET_BALANCE_SUCCESS,
  payload,
});
export const setIsLoadingGetDeposit = (payload) => ({
  type: types.IS_LOADING_GET_BALANCE,
  payload,
});
//GET_BALANCE_TRANSACTION
export const getDepositTransaction = (payload) => ({
  type: types.GET_BALANCE_TRANSACTION,
  payload,
});
export const getDepositTransactionSuccess = (payload) => ({
  type: types.GET_BALANCE_TRANSACTION_SUCCESS,
  payload,
});
export const setIsLoadingGetDepositTransaction = (payload) => ({
  type: types.IS_LOADING_GET_BALANCE_TRANSACTION,
  payload,
});
export const getBalanceEst = (payload) => ({
  type: types.GET_BALANCE_ESTIMATED,
  payload,
});
export const getBalanceEstSuccess = (payload) => ({
  type: types.GET_BALANCE_ESTIMATED_SUCCESS,
  payload,
});
export const setIsLoadingGetBalanceEst = (payload) => ({
  type: types.IS_LOADING_GET_BALANCE_ESTIMATED,
  payload,
});
// REQUEST_WITHDRAWAL
export const requestWithdrawal = (payload) => ({
  type: types.REQUEST_WITHDRAWAL,
  payload,
});
export const requestWithdrawalSuccess = (payload) => ({
  type: types.REQUEST_WITHDRAWAL_SUCCESS,
  payload,
});
export const setIsLoadingRequestWithdrawal = (payload) => ({
  type: types.IS_LOADING_REQUEST_WITHDRAWAL,
  payload,
});
// CONFIRM_OTP_WITHDRAWAL
export const confirmWithdrawal = (payload) => ({
  type: types.CONFIRM_OTP_WITHDRAWAL,
  payload,
});
export const confirmWithdrawalSuccess = (payload) => ({
  type: types.CONFIRM_OTP_WITHDRAWAL_SUCCESS,
  payload,
});
export const setIsLoadingConfirmWithdrawal = (payload) => ({
  type: types.IS_LOADING_CONFIRM_OTP_WITHDRAWAL,
  payload,
});
export const resendOTP = (payload) => ({
  type: types.RESEND_OTP_WITHDRAWAL,
  payload,
});
export const setIsLoadingResendOTPWithdrawal = (payload) => ({
  type: types.IS_LOADING_RESEND_OTP,
  payload,
});

export const setMessage = (payload) => ({
  type: types.SET_MESSAGE,
  payload,
});
export const resetDeposit = (payload) => ({
  type: types.RESET_BALANCE,
});

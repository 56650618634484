export default {
  SELECTED_DESTINATION_BANK_ACCOUNT: "SELECTED_DESTINATION_BANK_ACCOUNT",
  //WITHDRAW_AMOUNT
  WITHDRAW_AMOUNT: "WITHDRAW_AMOUNT",
  PASSWORD_WITHDRAWAL: "PASSWORD_WITHDRAWAL",
  SET_MESSAGE: "SET_MESSAGE",
  //CONFIRM_OTP_WITHDRAWAL

  //GET_BALANCE
  GET_BALANCE: "GET_BALANCE",
  GET_BALANCE_SUCCESS: "GET_BALANCE_SUCCESS",
  IS_LOADING_GET_BALANCE: "IS_LOADING_GET_BALANCE",
  //GET_BALANCE_TRANSACTION
  GET_BALANCE_TRANSACTION: "GET_BALANCE_TRANSACTION",
  GET_BALANCE_TRANSACTION_SUCCESS: "GET_BALANCE_TRANSACTION_SUCCESS",
  IS_LOADING_GET_BALANCE_TRANSACTION: "IS_LOADING_GET_BALANCE_TRANSACTION",
  //GET_BALANCE_ESTIMATED
  GET_BALANCE_ESTIMATED: "get-balance-est",
  GET_BALANCE_ESTIMATED_SUCCESS: "get-balance-est-success",
  IS_LOADING_GET_BALANCE_ESTIMATED: "is-loading-balance-est",

  //
  GET_DOWNLOAD_BALANCE_HISTORY: "get-download-balance-history",
  GET_DOWNLOAD_BALANCE_HISTORY_SUCCESS: "get-download-balance-history-success",
  IS_LOADING_GET_DOWNLOAD_BALANCE_HISTORY:
    "is-loading-download-balance-history",

  //REQUEST_WITHDRAWAL
  REQUEST_WITHDRAWAL: "REQUEST_WITHDRAWAL",
  REQUEST_WITHDRAWAL_SUCCESS: "REQUEST_WITHDRAWAL_SUCCESS",
  IS_LOADING_REQUEST_WITHDRAWAL: "IS_LOADING_REQUEST_WITHDRAWAL",
  CONFIRM_OTP_WITHDRAWAL: "CONFIRM_OTP_WITHDRAWAL",
  CONFIRM_OTP_WITHDRAWAL_SUCCESS: "CONFIRM_OTP_WITHDRAWAL_SUCCESS",
  IS_LOADING_CONFIRM_OTP_WITHDRAWAL: "IS_LOADING_CONFIRM_OTP_WITHDRAWAL",
  RESEND_OTP_WITHDRAWAL: "RESEND_OTP_WITHDRAWAL",
  IS_LOADING_RESEND_OTP: "IS_LOADING_RESEND_OTP_WITHDRAWAL",
  //RESET_BALANCE
  RESET_BALANCE: "RESET-BALANCE",
};
